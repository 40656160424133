.app {
  text-align: center;
}

.app-header {
  background-color: #282c34;
  height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.app-body {
  background-color: #454649;
  height: 80vh;
}

.app-footer {
  background-color: #282c34;
  height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.app-link {
  color: #61dafb;
}

img {
  max-width: 100%;
  vertical-align: middle;
}

.title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10%;
}

.title {
  font-size: 24px;
  color: white;
}

.selector-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50%;
}

.menu-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20%;
  height: 100%;
}

.menu-item-container {
  display: flex;
  flex-direction: column;
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90%;
}

.menu-item {
  width: 50%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: stretch;
}

.puzzle-type-button {
  -webkit-appearance: none;
  -webkit-transition: .2s;
  transition:
    box-shadow .2s;
  background: rgba(0, 0, 0, 0.5);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  filter: drop-shadow(0 0 2px 2px rgba(0, 0, 0, 0.5));
  width: 100%;
  padding: 0;
  margin: 10px;
  border: none;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.puzzle-type-button:hover {
  box-shadow: 0 0 2px 2px rgb(0, 153, 255);
}

.puzzle-type-button.active {
  box-shadow: 0 0 2px 2px rgb(255, 174, 0);
}

.menu-button-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  flex: 1;
  width: 100%;
  height: 40%;
}

.menu-button {
  -webkit-appearance: none;
  -webkit-transition: .2s;
  transition: 
    background-color .2s,
    box-shadow .2s;
  width: 70%;
  height: 40px;
  border-radius: 10px;
  font-size: 20px;
  margin: 10px 0px;
  background: rgba(179, 179, 179, 0.5);
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.5);
  outline: none;
}

.menu-button:hover {
  background: rgba(179, 179, 179, 1);
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 1);
}

.menu-button.active {
  background: rgb(50, 158, 46);
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 1);
}

.input-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  height: 100%;
}

.input-puzzle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80%;
}

.input-puzzle {
  background-color: #282c34;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.5);
}

.input-puzzle-tile {
  padding: 0;
  border: none;
}

.input-puzzle-tile img {
  width: 64px;
  height: 64px;
}

.input-puzzle-tile-empty {
  float: none;
  width: 64px;
}

.input-text-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10%;
}

.input-text {
  color: red;
  visibility: hidden;
}

.input-text.active {
  visibility: visible;
}

.input-checkbox-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10%;
}

.input-checkbox {
  width: 20px;
  height: 20px;
  border-radius: 5px;
}

.input-checkbox-text {
  color: white;
}

.solution-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: wrap;
  width: 100%;
  height: 50%;
}

.solution-puzzle {
  background-color: #282c34;
  display: flex;
  padding: 0;
  margin: 10px;
  border: none;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.5);
}

.solution-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 64px;
}

.puzzle-solution-tile {
  max-width: 32px;
  max-height: 32px;
}

.puzzle-solution-tile-empty {
  float: none;
  width: 32px;
}

.solution-text {
  display: flex;
  color: red;
  display: none;
}

.solution-text.active {
  display: inline;
}

table {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  border-collapse: collapse;
}

table, td {
  border: 1px solid black;
}

tr {
  display: flex;
  flex-flow: row wrap;
}

td {
  display: flex;
  padding: 0px;
}

td.highlighted {
  border: 1px solid white;
}